import React from 'react';
import withStore from "@/hocs/withStore";

function LoaderComponent({...props}) {

    return (
        <div className="loader">
            <div className="loader-spinner"></div>
        </div>
    );
}

export default withStore(LoaderComponent)
